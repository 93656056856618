









  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component({
    name: 'Step',
    components: {},
    mixins: [],
  })
  export default class Step extends Vue {
    @Prop({ default: [] }) steps!: string[];

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
